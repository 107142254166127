<div class="container">
	<h3 class="title">Indirizzo di {{ addressType === AddressType.BILLING ? 'fatturazione' : 'spedizione' }}</h3>

	<form class="address-form" [formGroup]="formGroup">
		<div class="inline-items">
			<app-form-field [control]="getFormControl('firstName')"
							labelId="first-name"
							label="Nome"/>
			<app-form-field [control]="getFormControl('lastName')"
							labelId="last-name"
							label="Cognome"/>
		</div>
		<app-form-field [control]="getFormControl('companyName')"
						labelId="company"
						label="Nome della società (opzionale)"/>
		<p-dropdown
				formControlName="country"
				[options]="countries"
				optionLabel="name"
				placeholder="Paese/Regione"
				filter="true"
				filterBy="name"
				styleClass="dropdown-wrapper"/>
		<app-form-field [control]="getFormControl('address')"
						labelId="address"
						label="Via e numero"/>
		<app-form-field [control]="getFormControl('apartment')"
						labelId="apartment"
						label="Appartamento, suite, unità, ecc. (opzionale)"/>
		<app-form-field [control]="getFormControl('zipCode')"
						labelId="zip-code"
						label="C.A.P."/>
		<app-form-field [control]="getFormControl('city')"
						labelId="city"
						label="Città"/>
		<div class="row">
			@if (getFormControlValue('country')?.provinces) {
				<p-dropdown
						formControlName="province"
						[options]="getFormControlValue('country').provinces"
						optionLabel="name"
						placeholder="Provincia"
						filter="true"
						filterBy="name"
						styleClass="dropdown-wrapper"/>
			}
		</div>
		<app-form-field [control]="getFormControl('phone')"
						labelId="phone"
						label="Telefono"/>
		<app-form-field [control]="getFormControl('email')"
						labelId="email"
						label="Indirizzo email"/>
	</form>

	<button class="save-button" (click)="saveAddress()">SALVA INDIRIZZO</button>
</div>
