<div class="container">
	<div class="header">
		<div class="greetings">
			Ciao <span class="user">{{ userFullName }}!</span>
		</div>

		<div class="description">
			Dalla bacheca del tuo account puoi visualizzare i tuoi ordini recenti, gestire i tuoi indirizzi di
			spedizione e fatturazione e modificare la password e i dettagli dell'account.
		</div>

		<div class="tiles-wrapper">
			@for (menuItem of menuItems; track menuItem.name) {
				<a [routerLink]="menuItem.url" class="tile-wrapper">
					<img class="tile-icon"
						 [ngClass]="{
							'small': menuItem.iconSize === IconSize.SMALL,
							'medium': menuItem.iconSize === IconSize.MEDIUM,
							'large': menuItem.iconSize === IconSize.LARGE
						}" [src]="menuItem.icon" alt="icon">
					<span class="name">{{ menuItem.name }}</span>
				</a>
			}
		</div>
	</div>
</div>