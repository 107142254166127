<div class="container">
	<div class="header">
		I seguenti indirizzi saranno usati come predefiniti nella pagina di riepilogo dell'ordine.
	</div>

	<div class="addresses-wrapper">
		@for (address of addresses; track address.id) {
			<div class="address-wrapper">
				<div class="address-type">
					<h3 class="label">INDIRIZZO DI {{ AddressType.BILLING === address.addressType ? 'FATTURAZIONE' : 'SPEDIZIONE' }}</h3>
					<a class="edit-button" [routerLink]="'edit/' + address.addressType.toLowerCase()">Modifica</a>
				</div>
				<div class="address">
					<p class="full-name">{{ address.firstName }} {{ address.lastName }}</p>
					<p class="address">{{ address.address }}</p>
					<p class="zip-code">{{ address.zipCode }}</p>
					<p class="city">{{ address.city }}</p>
					<p class="province">{{ address.province.name }}</p>
				</div>
			</div>
		}
	</div>
</div>