import { MenuItem } from "../../shared-components/models/menu-item";
import { IconSize } from "../../shared-components/models/icon-size";

export const accountMenuItems: MenuItem[] = [
	{
		name: 'Bacheca',
		url: '/account',
	},
	{
		name: 'Ordini',
		url: '/account/orders',
		icon: '/assets/images/order.svg',
		iconSize: IconSize.MEDIUM
	},
	{
		name: 'Indirizzi',
		url: '/account/addresses',
		icon: '/assets/images/addresses.svg',
		iconSize: IconSize.MEDIUM
	},
	{
		name: 'Dettagli account',
		url: '/account/details',
		icon: '/assets/images/user.svg',
		iconSize: IconSize.MEDIUM
	},
	{
		name: 'Wishlist',
		url: '/wishlist',
		icon: '/assets/images/wishlist.svg',
		iconSize: IconSize.MEDIUM
	},
	{
		name: 'Logout',
		url: '/auth/logout',
		icon: '/assets/images/logout.svg',
		iconSize: IconSize.MEDIUM
	}
];