import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserAddress } from "../../../shared-components/models/user-address";
import { AddressType } from "../../../shared-components/models/enumeration/address-type";
import { Subscription } from "rxjs";
import { UserService } from "../../../service/user.service";

@Component({
	selector: 'app-addresses',
	templateUrl: './addresses.component.html',
	styleUrl: './addresses.component.css'
})
export class AddressesComponent implements OnInit, OnDestroy {
	public addresses?: UserAddress[];

	private addressesSubscription?: Subscription;

	constructor(private userAddressService: UserService) {
	}

	ngOnInit() {
		this.addressesSubscription = this.userAddressService.getUserAddresses()
			.subscribe({
				next: addresses => this.addresses = addresses
			});
	}

	ngOnDestroy() {
		this.addressesSubscription?.unsubscribe();
	}

	protected readonly AddressType = AddressType;
}
