import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Order } from "../shared-components/models/order";
import { Page } from "../shared-components/models/page";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class OrderService {

	constructor(private httpClient: HttpClient) {
	}

	public getUserOrders = (page: number, pageSize: number): Observable<Page<Order>> => {
		return this.httpClient.get<Page<Order>>(`${environment.apiUrl}/orders?page=${page}&pageSize=${pageSize}`);
	}

}
