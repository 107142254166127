import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from "../../models/menu-item";
import { IconSize } from "../../models/icon-size";
import { AuthService } from "../../../services/auth.service";
import { map } from "rxjs";

@Component({
	selector: 'app-tiles-menu',
	templateUrl: './tiles-menu.component.html',
	styleUrl: './tiles-menu.component.css'
})
export class TilesMenuComponent implements OnInit {
	@Input() public menuItems?: MenuItem[];
	protected readonly IconSize = IconSize;
	public userFullName?: string;

	constructor(authService: AuthService) {
		authService.getUserDetails()
			.pipe(
				map(userDetails => [userDetails.firstName, userDetails.lastName].join(' '))
			)
			.subscribe({
				next: fullName => this.userFullName = fullName
			});
	}

	ngOnInit() {
		this.menuItems = this.menuItems?.filter(item => item.icon !== undefined);
	}
}
