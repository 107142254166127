import { Component } from '@angular/core';
import { accountMenuItems } from "../../models/account-menu-items";

@Component({
	selector: 'app-account-dashboard',
	templateUrl: './account-dashboard.component.html',
	styleUrl: './account-dashboard.component.css'
})
export class AccountDashboardComponent {
	protected readonly accountMenuItems = accountMenuItems;
}
