<div class="container">
	<div class="title">
		IL MIO ACCOUNT
	</div>

	<div class="items-wrapper">
		@for (menuItem of menuItems; track menuItem.name) {
			<a class="item" [routerLink]="menuItem.url" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ menuItem.name }}</a>
		}
	</div>
</div>