import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserAddress } from "../shared-components/models/user-address";
import { AddressType } from "../shared-components/models/enumeration/address-type";
import { UserDetails } from "../models/user-details";
import { UpdatePassword } from "../models/update-password";

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private httpClient: HttpClient) {
	}

	public getUserAddresses = (): Observable<UserAddress[]> => {
		return this.httpClient.get<UserAddress[]>(`${ environment.apiUrl }/users/addresses`);
	}

	public getUserAddressByType = (type: AddressType): Observable<UserAddress> => {
		return this.httpClient.get<UserAddress>(`${ environment.apiUrl }/users/addresses/${ type }`);
	}

	public saveUserAddress = (userAddress: UserAddress): Observable<void> => {
		return this.httpClient.post<void>(`${ environment.apiUrl }/users/addresses`, userAddress);
	}

	public updateUserDetails = (userDetails: UserDetails): Observable<void> => {
		return this.httpClient.put<void>(`${ environment.apiUrl }/users/details`, userDetails);
	}

	public updatePassword = (updatePassword: UpdatePassword): Observable<void> => {
		return this.httpClient.patch<void>(`${ environment.apiUrl }/users/password`, updatePassword);
	}
}
