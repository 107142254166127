import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { AuthService } from "../../../services/auth.service";
import { UserService } from "../../../service/user.service";
import { UserDetails } from "../../../models/user-details";
import { getFormControlValue } from "../../../utils/form-utils";
import { UpdatePassword } from "../../../models/update-password";

@Component({
	selector: 'app-account-details',
	templateUrl: './account-details.component.html',
	styleUrl: './account-details.component.css'
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
	public infoFormGroup: FormGroup;
	public passwordFormGroup: FormGroup;
	public showCurrentPassword: boolean;
	public showNewPassword: boolean;
	public showConfirmPassword: boolean;

	private keycloakDataSubscription?: Subscription;
	private editDataSubscription?: Subscription;
	private editPasswordSubscription?: Subscription;

	constructor(private authService: AuthService, private userService: UserService) {
		this.infoFormGroup = new FormGroup({
			firstName: new FormControl<string>('', [Validators.required]),
			lastName: new FormControl<string>('', [Validators.required]),
			displayName: new FormControl('', [Validators.required]),
			email: new FormControl<string>('', [Validators.required, Validators.email])
		});

		this.passwordFormGroup = new FormGroup({
			currentPassword: new FormControl('', [Validators.required]),
			newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
			confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
		});

		this.showCurrentPassword = false;
		this.showNewPassword = false;
		this.showConfirmPassword = false;
	}

	ngOnInit(): void {
		this.keycloakDataSubscription = this.authService.getUserDetails()
			.subscribe({
				next: userDetails => {
					this.infoFormGroup.patchValue({
						firstName: userDetails.firstName,
						lastName: userDetails.lastName,
						displayName: userDetails.attributes?.["displayName"] ?? '',
						email: userDetails.email
					});
				}
			});
	}

	ngOnDestroy(): void {
		this.keycloakDataSubscription?.unsubscribe();
		this.editDataSubscription?.unsubscribe();
		this.editPasswordSubscription?.unsubscribe();
	}

	public editData = (): void => {
		const userDetails: UserDetails = {
			firstName: getFormControlValue<string>(this.infoFormGroup, 'firstName') ?? '',
			lastName: getFormControlValue<string>(this.infoFormGroup, 'lastName') ?? '',
			displayName: getFormControlValue<string>(this.infoFormGroup, 'displayName') ?? '',
			email: getFormControlValue<string>(this.infoFormGroup, 'email') ?? '',
		};

		//TODO: Add toast message on success and on error
		this.editDataSubscription = this.userService.updateUserDetails(userDetails)
			.subscribe();
	}

	public editPassword = (): void => {
		this.authService.getUserDetails()
			.subscribe({
				next: userDetails => {
					const updatePassword: UpdatePassword = {
						loginCredentials: {
							username: getFormControlValue<string>(this.infoFormGroup, 'email') ?? '',
							password: getFormControlValue<string>(this.passwordFormGroup, 'currentPassword') ?? ''
						},
						newPassword: getFormControlValue<string>(this.passwordFormGroup, 'newPassword') ?? '',
						attributes: userDetails.attributes
					};

					//TODO: Add toast message on success and on error
					this.editPasswordSubscription = this.userService.updatePassword(updatePassword)
						.subscribe({
							error: err => console.error(err),
						});
				}
			});
	}
}
