import { Component } from '@angular/core';
import { accountMenuItems } from "../../models/account-menu-items";

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrl: './account.component.css'
})
export class AccountComponent {
	protected readonly accountMenuItems = accountMenuItems;
}
