import { Component, Input } from '@angular/core';
import { MenuItem } from "../../models/menu-item";

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrl: './side-menu.component.css'
})
export class SideMenuComponent {
	@Input() menuItems?: MenuItem[];
}
