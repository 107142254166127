import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { mergeMap, Subscription } from "rxjs";
import { UserService } from "../../../service/user.service";
import { UserAddress } from "../../../shared-components/models/user-address";
import { AddressType } from "../../../shared-components/models/enumeration/address-type";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Province } from "../../../cart/models/province";
import { Country } from "../../../cart/models/country";
import { getFormControlValue } from "../../../utils/form-utils";
import { CountryService } from "../../../cart/service/country.service";

@Component({
	selector: 'app-edit-address',
	templateUrl: './edit-address.component.html',
	styleUrl: './edit-address.component.css'
})
export class EditAddressComponent implements OnInit, OnDestroy {
	public formGroup: FormGroup;
	public addressType?: AddressType;
	public countries?: Country[];

	private countrySubscription?: Subscription;
	private addressSubscription?: Subscription;
	private paramsSubscription?: Subscription;

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private countryService: CountryService
	) {
		this.formGroup = new FormGroup({
			id: new FormControl<number>(0),
			firstName: new FormControl<string>('', Validators.required),
			lastName: new FormControl<string>('', Validators.required),
			companyName: new FormControl<string>(''),
			country: new FormControl<Country | undefined>(undefined, Validators.required),
			address: new FormControl<string>('', Validators.required),
			apartment: new FormControl<string>(''),
			zipCode: new FormControl<string>('', Validators.required),
			city: new FormControl<string>('', Validators.required),
			province: new FormControl<Province | undefined>(undefined, Validators.required),
			phone: new FormControl<string>('', Validators.required),
			email: new FormControl<string>('', Validators.required)
		});
	}

	ngOnInit(): void {
		this.countrySubscription = this.countryService.getAllCountries()
			.subscribe({
				next: countries => this.countries = countries
			});

		this.paramsSubscription = this.route.params
			.pipe(
				mergeMap(params => {
					this.addressType = params["address_type"].toUpperCase();
					return this.userService.getUserAddressByType(params["address_type"].toUpperCase());
				})
			)
			.subscribe({
				next: address => {
					this.formGroup.patchValue({
						id: address.id,
						firstName: address.firstName,
						lastName: address.lastName,
						companyName: '',
						country: address.country,
						address: address.address,
						apartment: address.apartment,
						zipCode: address.zipCode,
						city: address.city,
						province: address.province,
						phone: address.phone,
						email: address.email,
					})
				}
			});
	}

	ngOnDestroy() {
		this.countrySubscription?.unsubscribe();
		this.paramsSubscription?.unsubscribe();
		this.addressSubscription?.unsubscribe();
	}

	public getFormControl = (name: string): FormControl => {
		return this.formGroup.get(name) as FormControl;
	}

	public getFormControlValue = (name: string): any => {
		return getFormControlValue(this.formGroup, name);
	}

	public saveAddress = (): void => {
		const userAddress: UserAddress = {
			id: this.getFormControlValue('id'),
			firstName: this.getFormControlValue('firstName'),
			lastName: this.getFormControlValue('lastName'),
			country: this.getFormControlValue('country'),
			address: this.getFormControlValue('address'),
			apartment: this.getFormControlValue('apartment'),
			zipCode: this.getFormControlValue('zipCode'),
			city: this.getFormControlValue('city'),
			province: this.getFormControlValue('province'),
			phone: this.getFormControlValue('phone'),
			email: this.getFormControlValue('email'),
			addressType: this.addressType!
		};
		this.addressSubscription = this.userService.saveUserAddress(userAddress)
			.subscribe();
	}

	protected readonly AddressType = AddressType;
}
