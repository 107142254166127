@if (orders) {
	<p-table [value]="orders" [paginator]="orders.length > rows" [first]="first" [rows]="10" [rowsPerPageOptions]="[5, 10, 20]" (onPage)="pageChange($event)">
		<ng-template pTemplate="header">
			<tr>
				<th class="table-item header">ORDINE</th>
				<th class="table-item header">DATA</th>
				<th class="table-item header">STATO</th>
				<th class="table-item header">TOTALE</th>
				<th class="table-item header actions">AZIONI</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-order>
			<tr>
				<td class="table-item">
					<a class="table-item id" [routerLink]="order.id">
						#{{ order.id }}
					</a>
				</td>
				<td class="table-item">{{ getOrderDate(order) | date:'longDate' }}</td>
				<td class="table-item">{{ getOrderStatus(order) }}</td>
				<td class="table-item">{{ order.total | currency:'EUR' }} per {{ order.orderProducts.length }} articoli</td>
				<td class="table-item actions">
					<a class="view-button" [routerLink]="order.id">
						VISUALIZZA
					</a>
				</td>
			</tr>
		</ng-template>
	</p-table>
}