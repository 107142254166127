<div class="container">
	<form [formGroup]="infoFormGroup" class="form info">
		<div class="row-item">
			<div class="item">
				<span class="label">Nome *</span>
				<input type="text" class="input-value" formControlName="firstName"/>
			</div>

			<div class="item">
				<span class="label">Cognome *</span>
				<input type="text" class="input-value" formControlName="lastName"/>
			</div>
		</div>

		<div class="item">
			<span class="label">Nome visualizzato *</span>
			<input type="text" class="input-value" formControlName="displayName"/>
			<span class="description">Questo è il modo in cui il tuo nome verrà visualizzato nella sezione dell'account e nelle recensioni</span>
		</div>

		<div class="item">
			<span class="label">Indirizzo email *</span>
			<input type="text" class="input-value" formControlName="email"/>
		</div>

		<button class="save-button" (click)="editData()">Salva le modifiche</button>
	</form>

	<div class="password-form-wrapper">
		<form [formGroup]="passwordFormGroup" class="form bordered">
			<div class="item">
				<span class="label">Password attuale</span>
				<div class="inline">
					<input [type]="showCurrentPassword ? 'text' : 'password'" class="input-value"
						   formControlName="currentPassword"/>
					<button class="show-password" (click)="showCurrentPassword = !showCurrentPassword">
						<i class="pi pi-eye"></i>
					</button>
				</div>
			</div>

			<div class="item">
				<span class="label">Nuova password</span>
				<div class="inline">
					<input [type]="showNewPassword ? 'text' : 'password'" class="input-value"
						   formControlName="newPassword"/>
					<button class="show-password" (click)="showNewPassword = !showNewPassword">
						<i class="pi pi-eye"></i>
					</button>
				</div>
			</div>

			<div class="item">
				<span class="label">Conferma nuova password</span>
				<div class="inline">
					<input [type]="showConfirmPassword ? 'text' : 'password'" class="input-value"
						   formControlName="confirmPassword"/>
					<button class="show-password" (click)="showConfirmPassword = !showConfirmPassword">
						<i class="pi pi-eye"></i>
					</button>
				</div>
			</div>
		</form>

		<button class="save-button" [disabled]="!passwordFormGroup.valid" (click)="editPassword()">Modifica la password</button>
	</div>
</div>