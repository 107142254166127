import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order } from "../../../shared-components/models/order";
import { EOrderStatus } from "../../../shared-components/models/enumeration/e-order-status";
import { map, Subscription } from "rxjs";
import { OrderService } from "../../../services/order.service";
import { Pageable } from "../../../shared-components/models/pageable";
import { TablePageEvent } from "primeng/table";

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrl: './orders.component.css'
})
export class OrdersComponent implements OnInit, OnDestroy {
	public orders?: Order[];
	public first: number;
	public rows: number;

	private ordersSubscription?: Subscription;
	private pageable?: Pageable;

	constructor(private orderService: OrderService) {
		this.first = 0;
		this.rows = 10;
	}

	ngOnInit() {
		this.getOrders();
	}

	ngOnDestroy() {
		this.ordersSubscription?.unsubscribe();
	}

	public getOrderDate = (order: Order): Date => {
		return order.orderStatuses.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())[0].updatedAt;
	}

	public getOrderStatus = (order: Order): EOrderStatus => {
		return order.orderStatuses.sort((a, b) => a.updatedAt.getTime() - b.updatedAt.getTime())[0].status;
	}

	private getOrders = (): void => {
		if (this.pageable === undefined || !this.pageable.empty) {
			this.ordersSubscription = this.orderService.getUserOrders(this.first, this.rows)
				.pipe(
					map(ordersPage => {
						this.pageable = ordersPage.page;
						return ordersPage.content;
					})
				)
				.subscribe(orders => this.orders = orders);
		}
	}

	public pageChange = (event: TablePageEvent): void =>{
		this.first = event.first;
		this.rows = event.rows;
		this.getOrders();
	}
}
